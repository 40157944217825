<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <div class="manager_box">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{encnimg[num].title}}</div>
            <div class="manager_text">{{encnimg[num].content}}</div>
            <div class="mating_img">
              <img src="../img/introduce.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      encnimg:[
        {
          imgurl:require('../img/headerimg.png'),
          title:'物业管理',
          content:'羽翼物业是一个全球领先的房地产服务公司，提供房地产占领者和所有者的特殊价值。为各类物业提供管理服务，保障工作生产力，并提升物业组合的潜在价值。我们同时以综合方案模式向客户提供设施管理及资产管理服务，包括营运及维修、能源管理、租赁管理、资产维护与全生命周期计量与规划，以及重要设备的更新与项目管理服务等。'
        },
        {
          imgurl:require('../img/headerenimg.png'),
          title:'Property',
          content:'Wing Samui property is a leading real estate service company in the world, providing the special value to real estate occupiers and owners. Provide management services for various properties, ensure work productivity and enhance the potential value of the property portfolio. At the same time, we provide facilities management and asset management service to customers in a comprehensive scheme mode, including operation and maintenance, energy management, lease management, asset maintenance and life cycle measurement and planning, as well as important equipment renewal and project management services.'
        }
      ],
      navarr: [
        {
          0: ["物业管理"],

          1: ["Property"],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.manager_text {
  margin: 0.4rem auto;
  font-size: 0.17rem;
  line-height: 0.3rem;
  color: #666666;
  margin-bottom: 0.30rem;
}
.mating_img {
  img {
    width: 100%;
  }
}
.none {
  border: none !important;
}
.mat {
  margin: 0.3rem auto;
  border-bottom: 0.02rem solid #dad5ce;
}
.row {
  display: flex;
  width: 8rem;

  margin: 0.3rem auto;
}
.dis {
  width: 50%;
  display: flex;
  img {
    height: 0.3rem;
  }
  div {
    font-size: 0.17rem;
    line-height: 0.3rem;
    margin-left: 0.1rem;
  }
}
.manager_box {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.mating_img {
  img {
    width: 100%;
  }
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
</style>